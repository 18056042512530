import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { Button, Flex, H3 } from '@decisiv/ui-components';
import Refresh from '@decisiv/iconix/lib/components/Refresh';
import ExecutionDetailsModal from '../../components/ExecutionDetails/ExecutionDetailsModal';
import { IntegrationDetailLocations } from './style';
import WrapperWithLoading from '../../components/WrapperWithLoading';
import ExecutionsListTable from '../../components/ExecutionsListTable';
import LogInspectorService from '../../api/step_function_executions';

import EditMetadata from './EditMetadata';
import useRetryExecutionFunctions from '../../hooks/useRetryExecutionFunctions';

export default function ExecutionsList({ integration }) {
  const itemsPerPage = 25;

  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [currentEndIndex, setCurrentEndIndex] = useState(0);
  const [executions, setExecutions] = useState([]);
  const [currentPageExecutions, setCurrentPageExecutions] = useState([]);
  const [totalExecutionsPage, setTotalExecutionsPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedExecution, setSelectedExecution] = useState();
  const [executionModalDetailsVisibility, setExecutionModalDetailsVisibility] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const {
    isRetryButtonVisible,
    isRetryButtonDisabled,
    handleRetry,
    retryExecutionIdRef,
  } = useRetryExecutionFunctions();

  const pageChangeHandler = (newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  async function getExecutions(page = currentPage) {
    setLoading(true);
    const responseLogs =
      await LogInspectorService.fetchStepFunctionExecutionsByIntegrationId(
        integration.id,
        page,
      );
    if (responseLogs.data && responseLogs.data.length > 0) {
      setExecutions(responseLogs.data);

      const pageExecutions = responseLogs.data?.slice(0, 25);
      const startIndex = (currentPage - 1) * itemsPerPage;
      setCurrentStartIndex(startIndex);

      const endIndex = startIndex + pageExecutions.length;
      setCurrentEndIndex(endIndex);
      setTotalRecords(responseLogs.totalRecords);
      setTotalExecutionsPage(
        Math.ceil(responseLogs.totalRecords / itemsPerPage),
      );
      setCurrentPageExecutions(pageExecutions);
    }
    setLoading(false);
  }

  useEffect(() => {
    getExecutions(currentPage);
  }, [currentPage]);

  return (
    <IntegrationDetailLocations
      textAlign="center"
      name="integrationDetailLocations"
    >
      <ExecutionDetailsModal
        log={selectedExecution}
        visibility={executionModalDetailsVisibility}
        handleVisibility={setExecutionModalDetailsVisibility}
        modalTitle={t`Execution Details`}
        logs={executions}
        onRefreshLogs={() => getExecutions(currentPage)}
      />

      <Flex flexDirection="column" name="locationResult" flexGrow={1}>
        <Flex name="searchBar">
          <Flex
            marginTop={2}
            marginLeft={2}
            marginRight={2}
            name="topBar"
            flexGrow={1}
          >
            <Flex name="searchBox">
              <Flex>
                <H3 marginRight={2} marginTop={0.2}>
                  <Trans>Executions</Trans>
                </H3>
              </Flex>
            </Flex>
            <Flex
              flexDirection="row"
              name="filters"
              marginLeft={1.9}
              marginBottom={5}
            />
            <Flex justifyContent="flex-end" flexGrow={1}>
              <Button
                icon={Refresh}
                text={t`Refresh`}
                size="small"
                kind="secondary"
                onClick={() => getExecutions(currentPage)}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex name="searchResult">
          <WrapperWithLoading loading={loading}>
            <Flex flexGrow={1} flexDirection="column">
              <ExecutionsListTable
                currentPageExecutions={currentPageExecutions}
                currentStartIndex={currentStartIndex}
                currentEndIndex={currentEndIndex}
                itemsPerPage={itemsPerPage}
                executions={executions}
                totalPages={totalExecutionsPage}
                totalRecords={totalRecords}
                onPageChange={pageChangeHandler}
                currentPage={currentPage}
                setSelectedExecution={setSelectedExecution}
                isRetryButtonVisible={isRetryButtonVisible}
                isRetryButtonDisabled={isRetryButtonDisabled}
                handleRetry={handleRetry}
                retryExecutionIdRef={retryExecutionIdRef}
                setSortedExecutions={setCurrentPageExecutions}
                getExecutions={getExecutions}
                setExecutionModalDetailsVisibility={
                  setExecutionModalDetailsVisibility
                }
                tableColumns={[
                  'x_decisiv_trace_id',
                  'location_name',
                  'city',
                  'http_status',
                  'source',
                  'trigger_type',
                  'started',
                  'end_time',
                  'actions',
                ]}
              />
            </Flex>
          </WrapperWithLoading>
        </Flex>
      </Flex>
    </IntegrationDetailLocations>
  );
}

ExecutionsList.propTypes = {
  integration: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

EditMetadata.defaultProps = {
  integration: null,
};
