import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { useParams } from '@reach/router';
import { Button, Flex, H3, P } from '@decisiv/ui-components';
import { noop } from 'lodash';
import Refresh from '@decisiv/iconix/lib/components/Refresh';
import ExternalLink from '@decisiv/iconix/lib/components/ExternalLink';
import ExecutionDetailsModal from '../ExecutionDetails/ExecutionDetailsModal';
import useRetryExecutionFunctions from '../../hooks/useRetryExecutionFunctions';
import ExecutionsListTable from '../ExecutionsListTable';
import ConfiguredIntegrationsService from '../../api/configured_integrations';
import WrapperWithLoading from '../WrapperWithLoading';

export default function LocationExecutionLog({ setRefresh }) {
  const [currentPageExecutions, setCurrentPageExecutions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalExecutionsPage, setTotalExecutionsPage] = useState(0);
  const [executions, setExecutions] = useState([]);
  const [groupedExecutions, setGroupedExecutions] = useState([]);
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [currentEndIndex, setCurrentEndIndex] = useState(0);
  const [logModalDetailsVisibility, setLogModalDetailsVisibility] =
    useState(false);
  const [selectedLogDetail, setSelectedLogDetail] = useState();
  const [loadingExecutions, setLoadingExecutions] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const {
    isRetryButtonVisible,
    isRetryButtonDisabled,
    handleRetry,
    retryExecutionIdRef,
  } = useRetryExecutionFunctions();

  const params = useParams();
  const itemsPerPage = 5;
  const pageChangeHandler = (newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  async function getExecutions(page = currentPage) {
    setLoadingExecutions(true);
    const fetchExecutions =
      await ConfiguredIntegrationsService.fetchConfiguredIntegrationsExecutions(
        params.id,
        page,
      );

    if (fetchExecutions.data && fetchExecutions.data.length > 0) {
      setExecutions(fetchExecutions.data);

      const pageExecutions = fetchExecutions.data?.slice(0, 5);
      const startIndex = (currentPage - 1) * itemsPerPage;
      setCurrentStartIndex(startIndex);

      const endIndex = startIndex + itemsPerPage;
      setCurrentEndIndex(endIndex);
      setTotalRecords(fetchExecutions.totalRecords);
      setTotalExecutionsPage(
        Math.ceil(fetchExecutions.totalRecords / itemsPerPage),
      );

      setCurrentPageExecutions(pageExecutions);
    }
    setLoadingExecutions(false);
  }

  const groupExecutions = (items) => {
    const groupedArrays = [];

    items.forEach((item) => {
      const arn = item.attributes.step_function_arn;

      if (!groupedArrays[arn]) {
        groupedArrays[arn] = [];
      }

      groupedArrays[arn].push(item);
    });

    return Object.values(groupedArrays);
  };

  useEffect(() => {
    getExecutions(currentPage);
    const executionsByArn = groupExecutions(executions);

    setGroupedExecutions(executionsByArn);
  }, [currentPage]);

  return (
    <>
      <ExecutionDetailsModal
        log={selectedLogDetail}
        visibility={logModalDetailsVisibility}
        handleVisibility={setLogModalDetailsVisibility}
        logs={executions}
        onRefreshLogs={() => setRefresh(true)}
      />
      <Flex justifyContent="space-between">
        <Flex>
          <H3 marginBottom={1.8}>
            <Trans>Executions</Trans>
          </H3>
        </Flex>

        <Flex>
          <Button
            icon={Refresh}
            text={t`Refresh`}
            kind="secondary"
            size="small"
            style={{ marginRight: '0.5rem' }}
            onClick={() => getExecutions(currentPage)}
          />
          <Button
            icon={ExternalLink}
            text={t`See All`}
            kind="secondary"
            size="small"
            onClick={() =>
              window.open(
                'https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/statemachines',
                '_blank',
              )
            }
          />
        </Flex>
      </Flex>

      {groupedExecutions?.map((_group, index) => (
        <Flex flexDirection="column" marginTop={1}>
          <P color="alaskanHusky" weight="semibold">
            Arn:
          </P>
          <Flex>
            <Flex
              flexDirection="column"
              justifyContent="flex-start"
              flexWrap="nowrap"
            >
              <P color="alaskanHusky">
                {groupedExecutions[index][0].attributes.step_function_arn}
              </P>
            </Flex>
          </Flex>
        </Flex>
      ))}

      <WrapperWithLoading loading={loadingExecutions}>
        <Flex flexGrow={1} flexDirection="column">
          <ExecutionsListTable
            currentPageExecutions={currentPageExecutions}
            currentStartIndex={currentStartIndex}
            currentEndIndex={currentEndIndex}
            itemsPerPage={itemsPerPage}
            executions={executions}
            totalPages={totalExecutionsPage}
            totalRecords={totalRecords}
            onPageChange={pageChangeHandler}
            currentPage={currentPage}
            setSelectedExecution={setSelectedLogDetail}
            isRetryButtonVisible={isRetryButtonVisible}
            isRetryButtonDisabled={isRetryButtonDisabled}
            handleRetry={handleRetry}
            retryExecutionIdRef={retryExecutionIdRef}
            setSortedExecutions={setCurrentPageExecutions}
            getExecutions={getExecutions}
            setExecutionModalDetailsVisibility={setLogModalDetailsVisibility}
            tableColumns={[
              'x_decisiv_trace_id',
              'http_status',
              'source',
              'trigger_type',
              'started',
              'end_time',
              'actions',
            ]}
          />
        </Flex>
      </WrapperWithLoading>
    </>
  );
}

LocationExecutionLog.defaultProps = {
  setRefresh: noop,
};

LocationExecutionLog.propTypes = {
  setRefresh: PropTypes.func,
};
