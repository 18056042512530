import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import iconix from '@decisiv/iconix';

import { Button, Flex, H2, H4, P, Grid, Alert } from '@decisiv/ui-components';
import { t, Trans } from '@lingui/macro';
// eslint-disable-next-line import/no-duplicates
import Copy from '@decisiv/iconix/lib/components/Copy';
import { isEmpty } from 'lodash';
import TextField from '@decisiv/ui-components/lib/components/TextField';
import { FormGroup } from './StyledComponents';
import WrapperWithLoading from '../../components/WrapperWithLoading';
import ActionService from '../../api/actions';
import ConfiguredIntegrationService from '../../api/configured_integrations';
import ExecutionDetailsModal from '../../components/ExecutionDetails/ExecutionDetailsModal';
import {
  IntegrationDetailLocations,
  TextAreaDeveloperCodeView,
  DeveloperCodeView,
} from './style';

export default function ManualInvocation({ integration }) {
  const [loading, setLoading] = useState(false);
  const [invocationInput, setInvocationInput] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [srmAccountId, setSrmAccountId] = useState('');
  const [locationName, setLocationName] = useState('');
  const [srmAccountIdWarning, setSrmAccountIdWarning] = useState('');
  const [configuredIntegrationId, setConfiguredIntegrationId] = useState('');
  const [logModalDetailsVisibility, setLogModalDetailsVisibility] =
    useState(false);
  const [responseLog, setResponseLog] = useState('');
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('Something Went Wrong');
  const [disableInput, setDisableInput] = useState(false);

  const formatJsonText = (text) => {
    try {
      return JSON.stringify(JSON.parse(text), undefined, 2);
    } catch (error) {
      return t`Invalid Payload Format`;
    }
  };

  // Search for the SRM Account ID in the Configured Integrations endpoint
  // to get the location name and set the correct location id
  const handleSrmAccountIdChange = async (e) => {
    setSrmAccountId(e.target.value);

    if (e.target.value.length === 7) {
      setSrmAccountIdWarning('');
      setConfiguredIntegrationId('');
      setLocationName('');
      const responseConfiguredIntegration =
        await ConfiguredIntegrationService.fetchConfiguredIntegrationBySrmAccountAndIntegrationId(
          e.target.value,
          integration.id,
        );
      if (
        responseConfiguredIntegration.data &&
        responseConfiguredIntegration.data.length > 0
      ) {
        setLocationName(responseConfiguredIntegration.data[0].attributes.name);
        setConfiguredIntegrationId(responseConfiguredIntegration.data[0].id);
      } else {
        setSrmAccountIdWarning('SRM Account ID not found');
        setConfiguredIntegrationId('');
        setLocationName('');
      }
    } else {
      setSrmAccountIdWarning('');
      setConfiguredIntegrationId('');
      setLocationName('');
    }
  };

  async function getActionDetails() {
    if (
      integration.attributes.action === undefined ||
      integration.attributes.action === null ||
      integration.attributes.action === ''
    ) {
      setDisableInput(true);
      setDisplayAlert(true);
      setAlertTitle(t`Manual Execution Not Supported for This Integration`);
      setAlertMessage(
        t`This integration is triggered by Application Events and cannot be manually executed. Only integrations triggered by HTTP requests support manual execution`,
      );

      return;
    }
    setLoading(true);
    try {
      const responseActionDetails = await ActionService.fetchActionDetails(
        integration.attributes.action,
      );
      if (
        responseActionDetails.data &&
        responseActionDetails.data?.attributes?.name ===
          integration.attributes.action
      ) {
        setInvocationInput(
          formatJsonText(
            JSON.stringify(
              responseActionDetails.data.attributes.schema.example.data
                .attributes,
            ),
          ),
        );
      }
    } finally {
      setLoading(false);
    }
  }

  // Run the manual execution
  // eslint-disable-next-line consistent-return
  async function runManualInvocation() {
    setDisplayAlert(false);
    try {
      JSON.parse(invocationInput);
    } catch (e) {
      setDisplayAlert(true);
      setAlertTitle(t`Something Went Wrong`);
      setAlertMessage(t`Invalid Payload Format`);
      return false;
    }

    setButtonLoading(true);

    const invokePayload = {
      data: {
        type: 'integration_invocation',
        attributes: JSON.parse(invocationInput),
        relationships: {
          configured_integration: {
            data: {
              id: configuredIntegrationId,
              type: 'configured_integrations',
            },
          },
        },
      },
    };

    const responseInvoke = await ConfiguredIntegrationService.invokeIntegration(
      configuredIntegrationId,
      JSON.stringify(invokePayload),
      'INTEGRATION_TEST',
    );

    if (responseInvoke.status === 201) {
      setResponseLog(responseInvoke.data.data.attributes);
      setLogModalDetailsVisibility(true);
      setButtonLoading(false);
    } else {
      setDisplayAlert(true);
      setAlertTitle(t`Something Went Wrong`);
      setAlertMessage(responseInvoke.errors[0].detail);
      setButtonLoading(false);
    }
  }

  useEffect(() => {
    getActionDetails();
  }, []);

  return (
    <IntegrationDetailLocations
      textAlign="center"
      name="integrationDetailLocations"
    >
      <ExecutionDetailsModal
        log={responseLog}
        visibility={logModalDetailsVisibility}
        handleVisibility={setLogModalDetailsVisibility}
        logs={[]}
        onRefreshLogs={() => {}}
      />
      <Flex
        name="manualInvocation"
        flexDirection="column"
        flexGrow={1}
        marginTop={2}
        marginLeft={2}
        marginRight={2}
      >
        {displayAlert && (
          <Grid.Column span="12">
            <Alert intent="danger" title={alertTitle}>
              {alertMessage}
            </Alert>
          </Grid.Column>
        )}
        <Flex name="manualInvocationForm" marginTop={2}>
          <Grid.Container>
            <Grid.Row>
              <Grid.Column span="12">
                <Flex name="titleHeader">
                  <Flex>
                    <H2 marginRight={2} marginTop={0.2}>
                      <Trans>Manual Execution</Trans>
                      <P shade={1} size="small">
                        <Trans>
                          Create a manual execution for HTTP based integrations
                        </Trans>
                      </P>
                    </H2>
                  </Flex>
                </Flex>
              </Grid.Column>
            </Grid.Row>
            <br />
            <Grid.Row>
              <Grid.Column span="4">
                <H4>
                  <Trans>Location</Trans>
                  <P shade={1} size="small">
                    <Trans>Insert a SRM Account ID to select a location</Trans>
                  </P>
                </H4>
                <FormGroup key="metadata">
                  <TextField
                    label="SRM Account ID"
                    name="srm-account-id"
                    value={srmAccountId}
                    onChange={handleSrmAccountIdChange}
                    maxLength={7}
                    warningMessage={srmAccountIdWarning}
                    disabled={disableInput}
                  />
                </FormGroup>
                <FormGroup key="metadata">
                  <TextField
                    label="Location Name"
                    name="location-name"
                    value={locationName}
                    disabled
                  />
                </FormGroup>
                <FormGroup key="metadata">
                  <Button
                    aria-label={t`Run Manual Execution`}
                    text={t`Run Manual Execution`}
                    icon={iconix.ArrowRight}
                    iconPosition="right"
                    disabled={
                      isEmpty(invocationInput) ||
                      isEmpty(configuredIntegrationId)
                    }
                    loading={buttonLoading}
                    loadingText={t`Running`}
                    onClick={runManualInvocation}
                  />
                </FormGroup>
              </Grid.Column>
              <WrapperWithLoading loading={loading}>
                <Grid.Column span="8">
                  <H4>
                    <Trans>Input Invocation Payload</Trans>
                  </H4>
                  <br />
                  <Flex>
                    <DeveloperCodeView id="codeBox">
                      <TextAreaDeveloperCodeView
                        name="invocation-input"
                        value={invocationInput}
                        disabled={disableInput}
                        onChange={(e) => setInvocationInput(e.target.value)}
                      />
                    </DeveloperCodeView>
                    <Button
                      style={{
                        borderRadius: '4px',
                        marginRight: '10px',
                        marginTop: '20px',
                      }}
                      icon={Copy}
                      text={t`Copy`}
                      id="copyBtn"
                      variant="inverted"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          formatJsonText(invocationInput),
                        )
                      }
                      size="small"
                    />
                  </Flex>
                </Grid.Column>
              </WrapperWithLoading>
            </Grid.Row>
          </Grid.Container>
        </Flex>
      </Flex>
    </IntegrationDetailLocations>
  );
}

ManualInvocation.propTypes = {
  integration: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
