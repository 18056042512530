import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Breadcrumbs from '@decisiv/ui-components/lib/components/Breadcrumbs';
import {
  H1,
  H3,
  H4,
  P,
} from '@decisiv/ui-components/lib/components/Typography';
import { Button, Grid, Toggle, useNotifications } from '@decisiv/ui-components';
import iconix from '@decisiv/iconix';
import { Trans } from '@lingui/macro';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import { useParams } from '@reach/router';

import noop from 'lodash/noop';

import IntegrationsService from '../../api/integrations';
import ConfiguredIntegrationsService from '../../api/configured_integrations';
import Form from '../../components/LocationSettingsForm';
import WrapperWithLoading from '../../components/WrapperWithLoading';
import {
  EnableIntegration,
  HalfMoonBg,
  HeaderBlock,
  LocationContainer,
  RemoveIntegration,
} from './style';
import RemoveLocationModal from './RemoveLocationModal';
import ChangeHistory from '../../components/ChangeHistory';
import LocationExecutionLog from '../../components/LocationExecutionLog';

export default function LocationSettingsPage() {
  const [integration, setIntegration] = useState({});
  const [locationSettingsSchema, setLocationSettingsSchema] = useState({});
  const [location, setLocation] = useState({});
  const [toggle, setToggle] = useState('disabled');
  const [toggleDisabled, setToggleDisabled] = useState(false);
  const [toggleBg, setToggleBg] = useState(color.base.fullMoon);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [changeHistory, setChangeHistory] = useState({});
  const [formKey, setFormKey] = useState(1);

  const params = useParams();

  const handleModal = useCallback(() => {
    return setVisible(!visible);
  }, [visible]);

  useEffect(() => {
    async function getData() {
      const { data } =
        await ConfiguredIntegrationsService.fetchConfiguredIntegrationsByUuid(
          params.id,
        );

      if (data) {
        const fetchSingleResponse =
          await IntegrationsService.fetchIntegrationByUuid(
            data.attributes?.integration_uuid,
          );
        setIntegration(fetchSingleResponse.data);

        const schema =
          fetchSingleResponse.data.attributes.location_settings_schema;

        const commandsFlow = fetchSingleResponse.data.attributes.commands_flow;

        // This is a workaround because of how lodash handles sorts on object it removes the named key so we insert it
        // and them we sort and group it
        let prevAttrIndex = '0';
        schema.forEach((attribute, i) => {
          let attrIndex = attribute.name.match(/\d+/);
          if (attrIndex === null) {
            attrIndex = prevAttrIndex;
          }

          const commandIndex = (parseInt(attrIndex, 10) - 1) / 2;
          const commandName = commandsFlow[commandIndex];
          schema[i].sort_index = commandIndex;
          schema[i].command_name = `${commandName}${attrIndex}`;

          prevAttrIndex = attrIndex;
        });

        const sortedSchema = _.sortBy(schema, ['sort_index']);

        setLocationSettingsSchema(_.groupBy(sortedSchema, 'command_name'));

        setLocation(data);
        setToggle(data.attributes?.enabled ? 'enabled' : 'disabled');
        setToggleBg(
          data.attributes?.enabled
            ? color.visualInterest.fizzyLime.light
            : color.base.fullMoon,
        );

        const fetchChangeHistory =
          await ConfiguredIntegrationsService.fetchConfiguredIntegrationsChangeHistory(
            data?.id,
          );

        /* istanbul ignore else */
        if (fetchChangeHistory.data) {
          setChangeHistory(fetchChangeHistory.data);
        }
      } else {
        setLocation({});
        setToggle('disabled');
      }
      setLoading(false);
    }

    getData();
    setVisible(false);
    setRefresh(false);
  }, [location.length, refresh]);

  const current = `${location.attributes?.name} (ID ${location.attributes?.srm_account_id})`;
  const path = [
    { to: '/integrations', text: 'Integrations' },
    {
      to: `/integrations/${integration.id}`,
      text: integration.attributes?.name,
    },
  ];

  const { notify } = useNotifications();

  const successMessageBody = (data, e) => {
    return `${integration?.attributes?.name} for ${data?.attributes?.name} (${data?.attributes?.srm_account_id}) was ${e}.`;
  };

  const notificationsProps = (e) => {
    const str = e;

    return {
      intent: 'success',
      title: `${
        str.charAt(0).toUpperCase() + str.slice(1)
      } Integration Settings`,
      /* istanbul ignore next */
      onClose: noop,
    };
  };

  const handleToggle = (e) => {
    async function enableLocation() {
      const { data } = await IntegrationsService.toggleConfiguredIntegration(
        params.id,
        e === 'enabled',
      );

      /* istanbul ignore else */
      if (data) {
        setToggle(data.attributes?.enabled === true ? 'enabled' : 'disabled');
        setToggleBg(
          data.attributes?.enabled
            ? color.visualInterest.fizzyLime.light
            : color.base.fullMoon,
        );
        notify(notificationsProps(e), successMessageBody(data, e));
      }
    }

    enableLocation();
  };

  return (
    <>
      <Flex paddingX={2} paddingY={0.5}>
        <Breadcrumbs current={current} path={path} />
      </Flex>

      <HeaderBlock flexDirection="column" justifyContent="space-between">
        <WrapperWithLoading loading={loading}>
          <H1 marginBottom={1}>
            {location.attributes?.name}
            (ID {location.attributes?.srm_account_id})
          </H1>
          <span>
            <Trans>OEM</Trans> <b>{location.attributes?.oems}</b> •{' '}
            <Trans>Dealer Type</Trans>{' '}
            <b>{location.attributes?.account_type}</b> •{' '}
            <Trans>City, State </Trans>{' '}
            <b>
              {location.attributes?.city}, {location.attributes?.state}
            </b>
          </span>
        </WrapperWithLoading>
      </HeaderBlock>

      <HalfMoonBg>
        <Flex marginX={1}>
          <Grid.Container>
            <Grid.Row>
              <Grid.Column span="3">
                <EnableIntegration
                  style={{
                    backgroundColor: toColorString(toggleBg),
                  }}
                >
                  <H3 marginBottom={1}>{integration.name}</H3>

                  <Toggle
                    name="toggle"
                    options={[
                      { id: 'disabled', text: 'Disabled' },
                      { id: 'enabled', text: 'Enabled' },
                    ]}
                    onChange={handleToggle}
                    selected={toggle}
                    disabled={toggleDisabled}
                  />
                </EnableIntegration>
                <RemoveIntegration>
                  <H4>
                    <Trans>Remove Integration?</Trans>
                  </H4>

                  <P shade={1} size="small">
                    <Trans>You can always add it back.</Trans>
                  </P>

                  <Flex marginTop={1}>
                    <Button
                      text="Remove Integration"
                      icon={iconix.MinusCircle}
                      variant="ghost"
                      iconPosition="left"
                      onClick={handleModal}
                    />
                  </Flex>
                </RemoveIntegration>
              </Grid.Column>
              <Grid.Column>
                <WrapperWithLoading loading={loading}>
                  <Form
                    key={formKey}
                    locationSettingsSchema={locationSettingsSchema}
                    configuredIntegration={location}
                    locationSettingsAttributes={
                      location.attributes?.location_settings_attributes
                    }
                    setLocation={setLocation}
                    setToggleDisabled={setToggleDisabled}
                    integration={integration}
                    setFormKey={setFormKey}
                  />
                </WrapperWithLoading>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column span="3" />
              <Grid.Column span="9">
                <Grid.Container>
                  <LocationContainer>
                    <LocationExecutionLog setRefresh={setRefresh} />
                  </LocationContainer>
                </Grid.Container>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column span="3" />
              <Grid.Column span="9">
                <Grid.Container>
                  <WrapperWithLoading loading={loading}>
                    <LocationContainer>
                      <ChangeHistory
                        histories={changeHistory}
                        integration={integration}
                        location={location}
                      />
                    </LocationContainer>
                  </WrapperWithLoading>
                </Grid.Container>
              </Grid.Column>
            </Grid.Row>
          </Grid.Container>
        </Flex>
      </HalfMoonBg>

      <RemoveLocationModal
        visible={visible}
        onClose={handleModal}
        integration={integration}
        location={location}
      />
    </>
  );
}
