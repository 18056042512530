import api from './api';

const fetchStepFunctionExecutionsByTraceId = async (traceId) => {
  try {
    const { data } = await api.get(
      `/log-inspector?filter[x_decisiv_trace_id]=${traceId}`,
    );

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const fetchStepFunctionExecutionsByParentTraceId = async (traceId) => {
  try {
    const { data } = await api.get(
      `/log-inspector?filter[parent_x_decisiv_trace_id]=${traceId}`,
    );

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const fetchStepFunctionExecutionsByIntegrationId = async (
  integrationId,
  page = 1,
  limit = 25,
) => {
  try {
    const { data } = await api.get(
      `/log-inspector?filter[integration_id]=${integrationId}`,
      {
        params: {
          'page[number]': page,
          'page[size]': limit,
        },
      },
    );

    return { data: data.data, totalRecords: data.meta.total_records };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

export default {
  fetchStepFunctionExecutionsByTraceId,
  fetchStepFunctionExecutionsByParentTraceId,
  fetchStepFunctionExecutionsByIntegrationId,
};
